//Core
import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import get from 'lodash.get';
import loadable from '@loadable/component';
import LazyLoad from 'react-lazyload';
import { Box, CircularProgress } from '@material-ui/core';
//Local
import { Layout } from '../../components';
import * as MediaCenterActions from '../../redux/mediaCenter/actions';

const CardsSetSec = loadable(() =>
  import('../../components/mediaCenterSections/blogPreview/cardsSet')
);
const CardArticlesListSec = loadable(() =>
  import('../../components/mediaCenterSections/blogPreview/cardArticlesList')
);

const MediaCenterTop = () => {
  const { data, isFetching } = useSelector(
    (state) => state.mediaCenter.topPage
  );
  const dispatch = useDispatch();
  const topPage = data;

  // Did mount
  useEffect(() => {
    dispatch(MediaCenterActions.getTopPageAsync());
  }, [dispatch]);

  return (
    <Layout isMediaCenter>
      {isFetching ? (
        <Box
          display="flex"
          alignItems="center"
          justifyContent="center"
          py="100px"
        >
          <CircularProgress color="inherit" />
        </Box>
      ) : (
        <>
          <LazyLoad once height={1000} offset={100}>
            <CardsSetSec
              title={get(topPage, 'pageTitle')}
              subtitle={get(topPage, 'pageSubtitle')}
              primaryCard={{
                ...get(topPage, 'firstSectionPrimaryArticle.fields', {}),
                imgUrl: get(
                  topPage,
                  'firstSectionPrimaryArticle.fields.previewImage.fields.file.url'
                ),
                url: `/media_center/${get(
                  topPage,
                  'firstSectionPrimaryArticle.fields.blogSection.fields.urlKey'
                )}/article/${get(
                  topPage,
                  'firstSectionPrimaryArticle.sys.id'
                )}`,
                tags: get(
                  topPage,
                  'firstSectionPrimaryArticle.fields.tags',
                  []
                ).map((item) => item.fields.title),
              }}
              secondaryCard={{
                ...get(topPage, 'firstSectionSecondaryArticle.fields', {}),
                imgUrl: get(
                  topPage,
                  'firstSectionSecondaryArticle.fields.previewImage.fields.file.url'
                ),
                url: `/media_center/${get(
                  topPage,
                  'firstSectionSecondaryArticle.fields.blogSection.fields.urlKey'
                )}/article/${get(
                  topPage,
                  'firstSectionSecondaryArticle.sys.id'
                )}`,
                tags: get(
                  topPage,
                  'firstSectionSecondaryArticle.fields.tags',
                  []
                ).map((item) => item.fields.title),
              }}
              secondaryCardTitle={get(
                topPage,
                'firstSectionSecondaryArticleTitle'
              )}
              tertiaryCards={get(
                topPage,
                'firstSectionTertiaryArticles',
                []
              ).map((item) => ({
                ...item.fields,
                imgUrl: item.fields.previewImage.fields.file.url,
                url: `/media_center/${get(
                  item,
                  'fields.blogSection.fields.urlKey'
                )}/article/${get(item, 'sys.id')}`,
                tags: get(item, 'fields.tags', []).map(
                  (category) => category.fields.title
                ),
              }))}
              tertiaryCardsTitle={get(
                topPage,
                'firstSectionTertiaryArticlesTitle'
              )}
            />
          </LazyLoad>

          <LazyLoad once height={700} offset={1000}>
            <CardArticlesListSec
              title={get(topPage, 'secondSectionTitle')}
              card={{
                ...get(topPage, 'secondSectionPrimaryArticle.fields', {}),
                imgUrl: get(
                  topPage,
                  'secondSectionPrimaryArticle.fields.previewImage.fields.file.url'
                ),
                url: `/media_center/${get(
                  topPage,
                  'secondSectionPrimaryArticle.fields.blogSection.fields.urlKey'
                )}/article/${get(
                  topPage,
                  'secondSectionPrimaryArticle.sys.id'
                )}`,
                tags: get(
                  topPage,
                  'secondSectionPrimaryArticle.fields.tags',
                  []
                ).map((item) => item.fields.title),
              }}
              articles={get(topPage, 'secondSectionSecondaryArticles', []).map(
                (item) => ({
                  ...item.fields,
                  url: `/media_center/${get(
                    item,
                    'fields.blogSection.fields.urlKey'
                  )}/article/${get(item, 'sys.id')}`,
                  tags: get(item, 'fields.tags', []).map(
                    (category) => category.fields.title
                  ),
                })
              )}
              footnoteLink={{
                title: 'Paidyファンの全記事はこちら',
                url: '/media_center/fun/',
              }}
              hasBackground
            />
          </LazyLoad>

          <LazyLoad once height={800} offset={1700}>
            <CardArticlesListSec
              title={get(topPage, 'thirdSectionTitle')}
              card={{
                ...get(topPage, 'thirdSectionPrimaryArticle.fields', {}),
                imgUrl: get(
                  topPage,
                  'thirdSectionPrimaryArticle.fields.previewImage.fields.file.url'
                ),
                url: `/media_center/${get(
                  topPage,
                  'thirdSectionPrimaryArticle.fields.blogSection.fields.urlKey'
                )}/article/${get(
                  topPage,
                  'thirdSectionPrimaryArticle.sys.id'
                )}`,
                tags: get(
                  topPage,
                  'thirdSectionPrimaryArticle.fields.tags',
                  []
                ).map((item) => item.fields.title),
              }}
              articles={get(topPage, 'thirdSectionSecondaryArticles', []).map(
                (item) => ({
                  ...item.fields,
                  url: `/media_center/${get(
                    item,
                    'fields.blogSection.fields.urlKey'
                  )}/article/${get(item, 'sys.id')}`,
                  tags: get(item, 'fields.tags', []).map(
                    (category) => category.fields.title
                  ),
                })
              )}
              footnoteLink={{
                title: 'Paidyプレスの全記事はこちら',
                url: '/media_center/press/',
              }}
            />
          </LazyLoad>

          <LazyLoad once height={700} offset={2400}>
            <CardArticlesListSec
              title={get(topPage, 'fourthSectionTitle')}
              card={{
                ...get(topPage, 'fourthSectionPrimaryArticle.fields', {}),
                imgUrl: get(
                  topPage,
                  'fourthSectionPrimaryArticle.fields.previewImage.fields.file.url'
                ),
                url: `/media_center/${get(
                  topPage,
                  'fourthSectionPrimaryArticle.fields.blogSection.fields.urlKey'
                )}/article/${get(
                  topPage,
                  'fourthSectionPrimaryArticle.sys.id'
                )}`,
                tags: get(
                  topPage,
                  'fourthSectionPrimaryArticle.fields.tags',
                  []
                ).map((item) => item.fields.title),
              }}
              articles={get(topPage, 'fourthSectionSecondaryArticles', []).map(
                (item) => ({
                  ...item.fields,
                  url: `/media_center/${get(
                    item,
                    'fields.blogSection.fields.urlKey'
                  )}/article/${get(item, 'sys.id')}`,
                  tags: get(item, 'fields.tags', []).map(
                    (category) => category.fields.title
                  ),
                })
              )}
              footnoteLink={{
                title: 'Paidyワーク&ライフの全記事はこちら',
                url: '/media_center/work_life/',
              }}
              hasBackground
            />
          </LazyLoad>
        </>
      )}
    </Layout>
  );
};

export default MediaCenterTop;
